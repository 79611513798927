import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const prod_year = new Date();
  return (
    <footer className="relative text-white p-4">
      <div className="px-15 py-10 max-w-screen-xl mx-auto">
        <div className="flex flex-col justify-between  md:flex md:flex-row w-full mb-10">
          <div className="flex-col w-1/2">
            <div className="">
              <Link to="/" className="text-3xl tracking-wide font-bold">
                {/* <img className="w-20" src={logo} alt="logo" /> */}
                Mbeki Yunana Foundation
              </Link>
            </div>
          </div>
          <div className="w-full mt-5 md:mt-0 md:w-2/3">
            <div className="flex flex-col md:flex md:flex-row w-full">
              <div className="flex flex-col w-full mb-5 mt-5 md:mt-0  md:w-1/4">
                <h3 className="font-bold pb-2.5 text-xl font-jakarta">
                  General
                </h3>
                <ul className="flex flex-col gap-y-2">
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="#">Home</Link>
                  </li>
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="/updates">Updates</Link>
                  </li>
                  <li className="hover:underline hover:underline-offset-2">
                    <a
                      href="https://www.facebook.com/profile.php?id=61555651294339&mibextid=ZbWKwL"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Shíshèn
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col w-full mb-5 mt-5 md:mt-0  md:w-1/4">
                <h3 className="font-bold pb-2.5 text-xl font-jakarta">
                  Work with us
                </h3>
                <ul className="flex flex-col gap-y-2">
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="/about">About</Link>
                  </li>
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="/about">Careers</Link>
                  </li>
                  <li className="hover:underline hover:underline-offset-2">
                    <Link to="/contact">Contact us</Link>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col w-full mb-5 mt-5 md:mt-0  md:w-2/4">
                <h3 className="font-bold pb-2.5 text-xl font-jakarta">
                  Contact
                </h3>
                <div>
                  {/* <p>Whatsapp: +1 (860) 461-4235</p> */}
                  <p>Calls & Whatsapp: +234 706 306 8497</p>
                  <p>Email: hello@yunanaimpact.org</p>
                </div>
                <div className="mt-4 font-normal"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between pt-4">
          <div>
            <p className="text-sm">
              © Mbeki Yunana Foundation, {prod_year.getFullYear()}
            </p>
          </div>
          <div>
            <p className="text-sm">
              Terms and conditions · Privacy policy · Cookies
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
