import React from "react";

import project from "../assets/images/project.jpeg";

const SchoolProject = () => {
  return (
    <>
      <section class="bg-white mx-auto max-w-screen-xl">
        <div class="flex flex-row items-center gap-20">
          <div className="mx-auto max-w-screen-xl p-4 flex flex-col items-center">
            <h1 class="mb-4 text-3xl font-extrabold tracking-tight leading-none text-blue-950 md:text-4xl">
              The Mbeki Yunana Foundation is excited to announce the
              establishment of a new school in Nasarawa State, Nigeria
            </h1>
            <img
              class="mt-4 w-full lg:mt-10 rounded-lg"
              src={project}
              alt="office content 2"
            />
          </div>
        </div>
      </section>
      <section class="bg-white">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div class="text-zinc-700 ">
            <p class="mb-4">
              This school will be founded on a curriculum centered around
              peace-building approaches to learning, aiming to create a
              nurturing environment where students can thrive academically and
              emotionally.
            </p>
            <p class="mb-4">
              Our choice of Nasarawa State reflects our commitment to providing
              quality education in underserved areas, ensuring that every child
              has the opportunity to learn and grow in a supportive setting.
            </p>
            <p class="mb-4">
              We invite you to join us in making this vision a reality. Your
              support and contributions can help us build a place where
              education is not just about knowledge, but also about fostering
              peace, understanding, and community. Together, we can create a
              brighter future for the children!
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default SchoolProject;
