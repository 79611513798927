import React from "react";
import { motion } from "framer-motion";

const Donate = () => {
  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <section className="bg-white">
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:py-5 lg:px-6">
          <div className="text-zinc-700 ">
            <h2 className="mb-4 text-3xl tracking-tight font-extrabold text-blue-950 ">
              Donate to the Mbeki Yunana Foundation
            </h2>
            <h4 className="mb-4 text-xl tracking-tight font-extrabold text-blue-950 ">
              Your Support Changes Lives
            </h4>
            <p className="mb-4">
              When you give to the Mbeki Yunana Foundation, you’re not just
              making a donation—you're providing safe shelter for women and
              children in need. Supporting literacy programs that opens up
              opportunities Funding wellness sessions that promote mental health
              Empowering individuals acquire skills that lead to self
              sufficiency funding mediation meetings that drive toward conflict
              transformation bridging a gap of need
            </p>
            <div className="mt-5">
              <h4 className="mb-1 text-xl tracking-tight font-extrabold text-blue-950 ">
                Why Donate?
              </h4>
              <ul className="flex flex-col gap-2">
                <li>Provide safe shelter for women and children in need.</li>
                <li>
                  Support literacy programs that open doors to education and
                  opportunity.
                </li>
                <li>
                  Fund wellness sessions that promote mental and emotional
                  healing.
                </li>
                <li>
                  Empower individuals through skill acquisition training and
                  economic independence.
                </li>
                <li>
                  Foster peaceful communities through mediation services and
                  conflict resolution.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <motion.a
              href="https://buy.stripe.com/7sIeXy3PAaClbRu7st"
              target="_blank"
              rel="noreferrer"
              className="bg-blue-950 text-white font-bold p-3 rounded-lg inline-flex items-center w-44"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <span className=" ml-2 flex flex-row">Donate Now</span>
            </motion.a>
            <p className="mb-4 italic">
              'Giving is the purest act of love—it transforms both the giver and
              the receiver, creating ripples of kindness that echo far beyond
              what we can see' Shíshèn (author)
            </p>
          </div>
          {/* <div class="grid grid-cols-2 gap-4 mt-8">
            <img
              class="w-full rounded-lg"
              src={nuhua_10}
              alt="office content 1"
            />
            <img
              class="mt-4 w-full lg:mt-10 rounded-lg"
              src={nuhua_11}
              alt="office content 2"
            />
          </div> */}
        </div>
      </section>
    </div>
  );
};

export default Donate;
