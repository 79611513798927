import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainLayout from "./layouts/MainLayout";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import About from "./pages/About";
import Donate from "./pages/Donate";
import Updates from "./pages/Updates";
import SchoolProject from "./pages/SchoolProject";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/donate" element={<Donate />} />
          <Route path="/updates" element={<Updates />} />
          <Route path="/updates/next-big-thing" element={<SchoolProject />} />
          <Route path="/contact" element={<Contact />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
