import React from "react";

import about_1 from "../assets/images/about_1.jpeg";
import about_2 from "../assets/images/about_2.jpeg";
import update_1 from "../assets/images/update_1.jpeg";
import update_2 from "../assets/images/update_2.jpeg";
import update_3 from "../assets/images/update_3.jpeg";
import update_4 from "../assets/images/update_4.jpeg";
import update_5 from "../assets/images/update_5.jpeg";
import update_6 from "../assets/images/update_6.jpeg";

const Updates = () => {
  return (
    <div className={` max-w-7xl mx-auto p-4`}>
      <div class="grid grid-cols-4 gap-4 mt-8">
        <img class="w-full rounded-lg" src={about_1} alt="office content 1" />
        <img
          class="mt-4 w-full lg:mt-10 rounded-lg"
          src={about_2}
          alt="office content 2"
        />
        <img class="w-full rounded-lg" src={update_3} alt="office content 1" />
        <img
          class="mt-4 w-full lg:mt-10 rounded-lg"
          src={update_4}
          alt="office content 2"
        />
        <img class="w-full rounded-lg" src={update_6} alt="office content 1" />
        <img
          class="mt-4 w-full lg:mt-10 rounded-lg"
          src={update_1}
          alt="office content 2"
        />
      </div>
    </div>
  );
};

export default Updates;
