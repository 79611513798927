import React from "react";

import about_1 from "../assets/images/about_1.jpeg";
import about_2 from "../assets/images/about_2.jpeg";

import mbeki from "../assets/images/mbeki.jpeg";
import tina from "../assets/images/tina.jpeg";
import sunday from "../assets/images/sunday.jpeg";
import godwin from "../assets/images/godwin.jpeg";

const About = () => {
  return (
    <>
      <section class="bg-white mx-auto max-w-screen-xl">
        <div class="flex flex-row items-center gap-20">
          <div className="mx-auto max-w-screen-xl p-4 flex flex-col items-center">
            {/* <a
          href="#"
          class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-white bg-zinc-900 rounded-full"
          role="alert"
        >
          <span class="text-xs bg-primary-600 rounded-full text-white px-4 py-1.5 mr-3">
            New
          </span>{" "}
          <span class="text-sm font-medium">
            Flowbite is out! See what's new
          </span>
          <svg
            class="ml-2 w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </a> */}
            <h1 class="mb-4 text-3xl font-extrabold tracking-tight leading-none text-blue-950 md:text-4xl">
              About Us
            </h1>
            <p class=" text-zinc-700">
              Welcome to The Mbeki Yunana Foundation, where we are "Transforming
              Lives, One Step at a Time". Established with a vision to o become
              a sanctuary where all women and children can find refuge, heal,
              rebuild, engage in dialogue, and transform their lives even after
              adversity.
            </p>
          </div>
        </div>
      </section>
      <section class="bg-white">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="text-zinc-700 ">
            <h2 class="mb-4 text-3xl tracking-tight font-extrabold text-blue-950 ">
              Our Story
            </h2>
            <p class="mb-4">
              Since 2012, the Mbeki Yunana Foundation has been committed to
              empowering women and children who have faced significant
              hardships, including traumatic experiences. Rooted in a passion
              for language literacy and shaped by the lived experiences of our
              founder, we strive to create opportunities for growth and
              resilience.
              <br />
              The foundation serves as a safe and supportive space where
              individuals can rebuild their lives with dignity and purpose.
              Sustained by the generosity of individuals and organizations, we
              are guided by our belief in the transformative power of education,
              community, and support.
            </p>
            <p>Our Services Include:</p>
            <div className="mt-1">
              <ul>
                <li>
                  <span class="font-bold">Housing: </span> Safe and secure
                  accommodations for individuals and families in need.
                </li>
                <li>
                  <span class="font-bold">Literacy Classes: </span> Programs to
                  enhance language and literacy skills, fostering independence
                  and confidence.
                </li>
                <li>
                  <span class="font-bold">
                    Wellness Sessions (Quietude Curriculum):{" "}
                  </span>
                  Holistic mental and emotional wellness programs tailored to
                  promote healing and stability.
                </li>
                <li>
                  <span class="font-bold">Skill Acquisition Training: </span>
                  Practical skill-building opportunities designed to empower
                  individuals and support their journey toward self-reliance.
                </li>
                <li>
                  <span class="font-bold">Mediation: </span> Professional
                  conflict resolution services to encourage understanding and
                  peaceful outcomes.
                </li>
              </ul>
              <p className="mt-5">
                At the Mbeki Yunana Foundation, we are dedicated to restoring
                hope for the population we serve.
              </p>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-4 mt-8">
            <img
              class="w-full rounded-lg"
              src={about_1}
              alt="office content 1"
            />
            <img
              class="mt-4 w-full lg:mt-10 rounded-lg"
              src={about_2}
              alt="office content 2"
            />
          </div>
        </div>
      </section>
      <section class="bg-white">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:py-10 lg:px-6">
          <div class="text-zinc-700 ">
            <h2 class="mb-4 text-3xl tracking-tight font-extrabold text-blue-950 ">
              Team
            </h2>
            <div className="mt-1">
              <ul className="flex flex-col gap-5">
                <li>
                  <img
                    class="mt-4 w-1/3 h-96 lg:mt-10 rounded-lg"
                    src={mbeki}
                    alt="office content 2"
                  />
                  <span class="font-bold text-2xl">
                    Mbeki Yunana - Director{" "}
                  </span>{" "}
                  <br />
                  Mbeki Yunana's journey is enriched by her firsthand encounters
                  with intersecting forms of oppression over time, which have
                  uniquely equipped her to empathize with and understand various
                  struggles. Her proficiency in language and literacy, coupled
                  with a deep dedication to the welfare of humanity, is
                  underscored by her achievements in Linguistics, International
                  Peacebuilding, and Psychology. <br /> <br />
                  Her vision is anchored in creating a world where individuals,
                  particularly women and children, can cultivate inner peace and
                  resilience amidst life's challenges. She firmly advocates that
                  meaningful progress in societal change hinges on educational
                  institutions embracing transformative approaches rather than
                  rigidly prescriptive ones.
                </li>
                <li>
                  <img
                    class="mt-4 w-1/3 lg:mt-10 rounded-lg"
                    src={tina}
                    alt="office content 2"
                  />
                  <span class="font-bold text-2xl">
                    Rev. Dr. Mrs. Tina Rabbah - Religious and Education
                    Consultant
                  </span>{" "}
                  <br />
                  Her life's work and achievements are a testament to her
                  unwavering commitment to education, spirituality, and
                  community development. Born and raised in a devout family,
                  Tina Rabbah developed a profound interest in religious studies
                  from a young age. Her early years were marked by a thirst for
                  knowledge and a deep spiritual calling, which laid the
                  foundation for her future endeavors. After completing her
                  secondary education with top honors, Tina pursued higher
                  studies at a prestigious theological seminary. There, she
                  delved into the intricacies of theology, philosophy, and
                  educational methodologies, honing her skills under the
                  guidance of eminent scholars and educators. Equipped with a
                  solid academic background and a passion for teaching, Tina
                  embarked on her career as an educator and religious leader.
                  Her journey took her across various educational institutions
                  and communities, where she became known for her innovative
                  teaching methods and compassionate approach to pastoral care.{" "}
                  <br />
                  Throughout her career, Tina Rabbah has been a staunch advocate
                  for the integration of faith and education. Her consultancy
                  work with MYfoundation exemplifies her dedication to fostering
                  holistic educational experiences that nurture both the
                  intellect and the spirit. She has played a pivotal role in
                  developing curricula that emphasize moral values, critical
                  thinking, and spiritual growth among students of all ages.
                  Beyond her professional achievements, Tina is admired for her
                  warmth, humility, and unwavering faith. She serves as a mentor
                  and role model to aspiring educators and religious leaders,
                  inspiring them to embrace their calling.
                </li>
                <li>
                  <img
                    class="mt-4 w-1/3 lg:mt-10 rounded-lg"
                    src={sunday}
                    alt="office content 2"
                  />
                  <span class="font-bold text-2xl">
                    Sunday Mamo Joel- Business Administration counselor.
                  </span>{" "}
                  <br />
                  With a wealth of expertise in business management, he offers
                  invaluable advice and support, ensuring the foundation
                  operates efficiently and effectively. In addition to his
                  business acumen, Sunday excels in ICT planning and
                  supervision, leveraging technology to enhance operations and
                  outreach which we enjoy. Beyond his professional
                  contributions, Sunday embodies a strong commitment to
                  philanthropy and community service, actively promoting a
                  culture of giving back. Through his multifaceted
                  contributions, Sunday Mamo Joel exemplifies leadership and
                  dedication in both business administration and community
                  development within MY Foundation.
                </li>
                <li>
                  <img
                    class="mt-4 w-1/3 lg:mt-10 rounded-lg"
                    src={godwin}
                    alt="office content 2"
                  />
                  <span class="font-bold text-2xl">
                    Godwin Egbe Jr - Consultant.
                  </span>{" "}
                  <br />
                  He lends his expertise as a Psychologist to MY Foundation.
                  With an understanding of human behavior and mental health, he
                  plays a crucial role in providing guidance and support within
                  the foundation. His counseling services are invaluable in
                  fostering a positive and supportive environment for staff and
                  beneficiaries. Beyond his professional role, Godwin Egbe Jr is
                  known for his advocacy for self-expression. He believes
                  strongly in the importance of individuals expressing
                  themselves authentically and finding their voice. Godwin Egbe
                  Jr's contributions go beyond traditional counseling; he
                  actively promotes mental well-being and emotional resilience,
                  enhancing the foundation's efforts in community support and
                  development. He embodies principles of empathy, empowerment,
                  and holistic care.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
