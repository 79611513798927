import React from "react";
import { Typewriter } from "react-simple-typewriter";
import { motion } from "framer-motion";
import { Animated } from "react-animated-css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import image_1 from "../assets/images/1.jpeg";
import image_2 from "../assets/images/2.jpeg";
import image_3 from "../assets/images/3.jpeg";
import image_4 from "../assets/images/4.jpeg";
import image_5 from "../assets/images/5.jpeg";
import image_1_1 from "../assets/images/image_1.jpeg";
import image_1_2 from "../assets/images/image_2.jpeg";
import image_1_3 from "../assets/images/image_3.jpeg";
import image_1_4 from "../assets/images/image_1.jpeg";
import update_1 from "../assets/images/update_1.jpeg";
import update_2 from "../assets/images/update_2.jpeg";
import update_3 from "../assets/images/update_3.jpeg";
import update_4 from "../assets/images/update_4.jpeg";
import update_5 from "../assets/images/update_5.jpeg";
import update_6 from "../assets/images/update_6.jpeg";
import about_2 from "../assets/images/about_2.jpeg";
import mediation from "../assets/images/mediation.jpeg";
import language from "../assets/images/language.jpeg";
import project from "../assets/images/project.jpeg";

const Home = () => {
  return (
    <>
      <div className={`bg-white p-4`}>
        <div className="flex flex-col-reverse md:flex md:flex-row justify-between py-10 my-10 max-w-screen-xl gap-10 mx-auto pt-5 md:pt-10 ">
          <div className="md:w-1/2 mb-2">
            <h1 className="text-4xl md:text-7xl font-pally font-extrabold">
              {/* <span className=" bg-app-bg bg-[length:100%]">
                <span className="">
                  
                  Best
                </span>
              </span> */}
              Nurturing Hope.
              <br />
            </h1>
            <p className="leading-relaxed text-lg mt-5 text-zinc-700">
              A sanctuary of hope, healing, and empowerment for women and
              children, where every heart finds solace and discovers its
              strength. Since 2012, we have stood beside those who have faced
              life’s harshest trials, offering a haven to rediscover and
              rebuild. Through compassion and transformative programs, we
              inspire new beginnings. Join us in our mission to uphold humanity.
            </p>
            <div className="flex flex-row gap-2 md:gap-7 justify-start mt-5">
              <motion.a
                href="/about"
                class="bg-blue-950 text-white font-bold p-3 rounded-lg inline-flex items-center w-56"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <span className=" ml-2 flex flex-row">
                  Learn More
                  <svg
                    className="w-4 ml-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4297 5.93018L20.4997 12.0002L14.4297 18.0702"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.5 12H20.33"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </motion.a>
              {/* <motion.a
                class="bg-white text-black font-bold border p-3 rounded-lg inline-flex items-center w-43 cursor-pointer"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                href="/contact"
              >
                <span className=" ml-2 flex flex-row">
                  Contact sales{" "}
                  <svg
                    className="w-4 ml-3"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4297 5.93018L20.4997 12.0002L14.4297 18.0702"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M3.5 12H20.33"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </motion.a> */}
            </div>
          </div>

          <motion.div
            className="md:w-1/2 flex justify-end"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Carousel autoPlay={true} infiniteLoop={true} showThumbs={false}>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={image_1_1}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={image_1_2}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={image_1_3}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={image_4}
                />
              </div>
              <div>
                <img
                  className="rounded-lg fit"
                  alt="landing.png"
                  src={image_5}
                />
              </div>
            </Carousel>
            {/* <img className="rounded-lg fit" src={portrait} alt="landing.png" /> */}
          </motion.div>
        </div>
      </div>

      <div className="bg-yellow-50 p-4 md:p-20">
        <Animated
          animationIn="fadeInRight"
          animationOut="fadeOut"
          isVisible={true}
        >
          <div className="md:flex md:flex-row flex flex-col justify-center items-center px-15 w-full mx-auto rounded-lg gap-5">
            <div className="w-full mb-7 md:mb-0 rounded-md">
              <div className="flex flex-row gap-3">
                <div className="">
                  <h1 className="text-4xl font-bold">Our Vision</h1>
                  <p className="text-zinc-700 mt-2 text-xl">
                    To be a platform where individuals experiencing any form of
                    vulnerability can find respite, healing, and empowerment,
                    fostering transformation from inside, out.
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-3 mt-5">
                <div className="">
                  <h1 className="text-4xl font-bold">Our Mission</h1>
                  <p className="text-zinc-700 mt-2 text-xl">
                    Through the quietude curriculum, which upholds programs
                    centered on compassion, we will build platforms where
                    individuals can find respite.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Animated>
      </div>
      <div className="bg-white p-4">
        <div className="md:flex justify-start  px-15 py-8 max-w-screen-xl mx-auto gap-5">
          <div className="flex flex-col w-full">
            <div className="flex flex-col">
              <h3 className="font-bold text-5xl mb-5">What We Do</h3>
            </div>
            <div className="flex flex-row gap-5 w-full mt-5 overflow-x-auto">
              <div className="w-full md:w-1/4 bg-blue-950 text-white p-5 rounded-lg">
                <div className="mb-5">
                  <img
                    className="rounded-lg w-96 h-44"
                    src={language}
                    alt="Chef"
                  />
                </div>
                <div className="mb-1 w-full">
                  <h3 className="font-bold text-2xl">Literacy Classes</h3>
                </div>
                <div className="w-full">
                  <p className="text-zinc-300">
                    Offering education and language literacy programs to help
                    individuals improve their reading and writing skills.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/4 bg-blue-950 text-white p-5 rounded-lg">
                <div className="mb-5">
                  <img
                    className="rounded-lg w-96 h-44"
                    src={about_2}
                    alt="Chef"
                  />
                </div>
                <div className="mb-1">
                  <h3 className="font-bold text-2xl">
                    Wellness Sessions (Quietude Curriculum)
                  </h3>
                </div>
                <div>
                  <p className="text-zinc-300">
                    Facilitating wellness programs designed to promote mental
                    and emotional well-being.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/4 bg-blue-950 text-white p-5 rounded-lg">
                <div className="mb-5">
                  <img
                    className="rounded-lg w-96 h-44"
                    src={image_3}
                    alt="Chef"
                  />
                </div>
                <div className="mb-1">
                  <h3 className="font-bold text-2xl">
                    Skill Acquisition Training
                  </h3>
                </div>
                <div>
                  <p className="text-zinc-300">
                    Equipping individuals with valuable skills to enhance their
                    employability and independence
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/4 bg-blue-950 text-white p-5 rounded-lg">
                <div className="mb-5">
                  <img
                    className="rounded-lg w-96 h-44"
                    src={mediation}
                    alt="Chef"
                  />
                </div>
                <div className="mb-1">
                  <h3 className="font-bold text-2xl">Mediation</h3>
                </div>
                <div>
                  <p className="text-zinc-300">
                    Offering mediation services to help resolve conflicts and
                    promote peaceful resolutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-yellow-50 p-4">
        <div className="md:flex justify-start  px-15 py-8 max-w-screen-xl mx-auto gap-5">
          <div className="flex md:flex-row flex-col-reverse w-full">
            <div className="md:w-2/5 flex flex-col items-center justify-center">
              <img className="rounded-lg" src={project} alt="Chef" />
            </div>
            <div className="md:w-3/5 flex flex-col justify-center md:p-28 p-5">
              <div ClassName="flex ">
                <h1 className=" font-bold text-5xl tracking-wide leading-snug md:leading-tight">
                  Next big thing!
                </h1>
                <h3 className="mt-5 text-zinc-700">
                  The Mbeki Yunana Foundation is excited to announce the
                  establishment of a new school in Nasarawa State, Nigeria. This
                  school will be founded on a curriculum centered around
                  peace-building approaches to learning, aiming to create a
                  nurturing environment where students can thrive academically
                  and emotionally.
                </h3>
              </div>
              <div className="mt-10">
                <motion.a
                  href="/updates/next-big-thing"
                  class="bg-blue-950 text-white font-bold p-3 rounded-lg  inline-flex items-center"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <span className="text-md ml-2 flex flex-row">
                    Read more ...
                  </span>
                </motion.a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-blue-950 p-4">
        <div class="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
          <figure class="max-w-screen-md mx-auto">
            <h2 className="text-white font-bold text-4xl mb-3">Get Involved</h2>

            <p class="text-2xl font-medium text-white dark:text-white">
              Walk and work with us toward a trauma-informed world
            </p>
            <motion.a
              href="/donate"
              class="bg-white text-blue-900 font-bold p-3 mt-5 rounded-lg inline-flex items-center"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <span className=" ml-2 flex flex-row text-2xl font-bold">
                Donate Now
              </span>
            </motion.a>
          </figure>
          <div className="flex flex-col md:flex-row gap-8 my-10 w-full justify-center">
            <img src={update_1} alt="Update" className="w-1/3 rounded-lg" />
            <img src={update_2} alt="Update" className="w-1/3 rounded-lg" />
            <img src={update_3} alt="Update" className="w-1/3 rounded-lg" />
          </div>
          <div className="flex flex-col md:flex-row gap-8 my-10 w-full justify-center">
            <img src={update_4} alt="Update" className="w-1/3 rounded-lg" />
            <img src={update_5} alt="Update" className="w-1/3 rounded-lg" />
            <img src={update_6} alt="Update" className="w-1/3 rounded-lg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
